import { notifyProblem, notifyError, notifyWarning } from '@/services/notify'
import { getVOccurrenceList } from '@/services/ViolationIssue/ViolationOccurrence/List'
import { listSteps } from '@/services/ViolationIssue/ViolationStep/List'
import { getMultiplePhotos } from '@/services/ViolationIssue/ViolationOccurrencePhoto/sequences/GetMultiple'
import { violationStepDocumentStore } from '@/services/ViolationIssue/ViolationStepDocument/store'
import _get from 'lodash/get'
import { getString } from '@/utilities/get/string'
import { parseDate } from '@/utilities/Date/parse'
import { base64toBlob, showAnyType } from '@/services/blobutility'
import moment from 'moment'

export const methods = {
  async reload() {
    this.loading = true
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    this.violationID = this.$route.params.id || 0

    this.hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    await this.getOccurrences()

    await this.getSteps({
      id: this.violationID
    })

    loadingComponent.close()
    this.loading = false
  },

  handleEllipsis() {
    this.showEllipsis = !this.showEllipsis
  },

  async openPhotoModal(photo) {
    if (photo !== undefined && photo) {
      this.photoModal = {
        open: true,
        src: photo.content
      }
      return
    }
  },

  async getOccurrences() {
    const { list, successful, message } = await getVOccurrenceList({
      json: {
        hoaID: this.hoaID,
        violationID: this.violationID
      }
    })
    if (!successful) {
      notifyProblem(message)
      this.loading = false
      return {
        successful: false
      }
    }
    //
    this.occurrences = list
    this.occurrence = list[0]
  },

  async getDocumentFile(id) {
    if (!id || id <= 0) {
      notifyError('Could not retrieve the violation letter')
      return
    }

    try {
      const params = {
        violationStepDocumentID: id,
        asRawBaseSixtyFour: true
      }

      await violationStepDocumentStore.dispatch('getFile', {
        params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('base64pdf........................' + details)

            const blob = base64toBlob(details, 'text/html')
            showAnyType(blob, 'text/html')

            console.debug(`Successfully downloaded the violation letter.`)
            return
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the violation letter.')
    }
  },

  async getSteps({ id }) {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    var { successful, message, steps } = await listSteps({
      params: {
        hoaID,
        violationID: id
      }
    })
    if (!successful) {
      notifyProblem(message)
      return false
    }

    const occurrences = this.occurrences
    const photosList = []

    this.steps = steps
      .map((step, index) => {
        const dateFormat = 'LL'

        const occurrence = _get(step, 'violationOccurrence', {})
        const violationStepID = _get(step, 'violationStepID', 0)

        console.debug('step=' + JSON.stringify(step))

        let photosMeta = []

        /* get the photos meta data from the corresponding occurence */
        for (let a = 0; a < occurrences.length; a++) {
          const o = occurrences[a]

          try {
            if (
              o.violationOccurrenceID === step.violationOccurrenceID &&
              Array.isArray(o.violationOccurrencePhotos)
            ) {
              photosMeta = o.violationOccurrencePhotos
              break
            }
          } catch (x) {
            console.error(x)
          }
        }

        const observationDate = getString(
          occurrence,
          'observationDate',
          moment('01/01/1900').format(dateFormat)
        )
        let observationDateParsed = parseDate(observationDate, 'LL')

        return {
          count: index + 1,
          actionName: getString(step, ['violationAction', 'name'], ''),
          observedBy: getString(occurrence, 'observedBy', ''),
          observationDate: observationDateParsed,
          description: getString(occurrence, 'description', ''),
          photosMeta,
          violationStepID,
          photos: [],
          violationLetters: []
        }
      })
      .reverse()

    let totalProblems = 0
    for (let a = 0; a < this.steps.length; a++) {
      const { photosMeta } = this.steps[a]

      const { photos, problems } = await getMultiplePhotos({
        hoaID,
        paramsList: photosMeta.map(photo => {
          return {
            violationOccurrencePhotoID: _get(photo, ['violationOccurrencePhotoID'], ''),
            asBase64: true
          }
        })
      })

      if (Array.isArray(problems) && problems.length >= 1) {
        totalProblems += problems.length
      }

      //Retrieve violation letters
      let violationLetters = []
      const params = {
        hoaID: this.hoaId,
        violationStepID: _get(this.steps[a], 'violationStepID', 0)
      }

      await violationStepDocumentStore
        .dispatch('getViolationStepDocumentList', {
          params
        })
        .then(({ list }) => {
          if (list) {
            list.forEach(f => {
              if (f) {
                violationLetters.push({
                  violationStepDocumentID: f.violationStepDocumentID,
                  documentFileName: f.documentFileName
                })
              }
            })
          }
        })

      console.debug('violationLetters=' + JSON.stringify(this.steps[a].violationLetters))

      this.steps[a].photos = photos
      this.steps[a].violationLetters = violationLetters
      photosList.push(...photos)
    }

    if (totalProblems >= 1) {
      notifyProblem(`${totalProblems} photos may not have been retrieved.`)
    }

    this.photosList = photosList

    const mainPhoto = _get(photosList, [0, 'content'], null)
    if (typeof mainPhoto === 'string') {
      this.mainPhoto = mainPhoto
    }

    this.$forceUpdate()

    return true
  }
}
